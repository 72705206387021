import React from 'react';
import { FadeLoader } from 'react-spinners';

function LoadingComponent(props) {
  if (props.isLoading) {
    if (props.timedOut) {
      window.location.reload();
      return <div>Loader timed out!</div>;
    } else if (props.pastDelay) {
      return (
        <div className="page-loader justify-content-center align-items-center vh-100">
          <em className="fas fa-circle-notch fa-spin fa-2x text-muted"></em>
        </div>
      );
    } else {
      return null;
    }
  } else if (props.error) {
    window.location.reload();
    return (
      <div className="container vh-100 d-flex justify-content-center">
        <div className="my-auto">
          <FadeLoader size={150} color={'#0019FF'} loading={true} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default LoadingComponent;
