export const storageKey = 'A234567dfgh7da89054d';

export const LINE_STATE = 'b41c8fd15234567dfgh7da89054d931e7s';
export const LINE_NONCE = 'd78a51235f6ee189e831q9c68523cfa336917ada';

export const SYS_ROLE_SUPER_ADMIN = 'super-admin';
export const SYS_ROLE_ADMIN = 'admin';
export const SYS_ROLE_CALL_CENTER = 'call-center';
export const SYS_ROLE_HEAD_TECHNICIAN = 'head-technician';
export const SYS_ROLE_TECHNICIAN = 'technician';
export const SYS_ROLE_PLANNER = 'planner';
export const SYS_ROLE_RETAIL = 'retail';