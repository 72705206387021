import Loadable from 'react-loadable';

// import components
import LoadingComponent from '../components/Common/LoadingComponent';
import { isLogin } from 'Utils/authUtils';
import {
    SYS_ROLE_SUPER_ADMIN,
    SYS_ROLE_ADMIN,
    SYS_ROLE_CALL_CENTER,
    SYS_ROLE_HEAD_TECHNICIAN,
    SYS_ROLE_PLANNER,
    SYS_ROLE_RETAIL
} from 'config/constants';
import { isRoutePermission } from 'Utils/authUtils';

const myRoutes = [
    {
        path: '/',
        exact: true,
        auth: false,
        component: Loadable({
            loader: () => import('../pages/LoginPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        component: Loadable({
            loader: () => import('../pages/LoginCallbackPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/dashboard',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_ADMIN, SYS_ROLE_CALL_CENTER, SYS_ROLE_HEAD_TECHNICIAN, SYS_ROLE_PLANNER, SYS_ROLE_RETAIL],
        component: Loadable({
            loader: () => import('../pages/DashboardPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/planning',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_HEAD_TECHNICIAN, SYS_ROLE_PLANNER],
        component: Loadable({
            loader: () => import('../pages/PlaningPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/task-service',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_CALL_CENTER, SYS_ROLE_HEAD_TECHNICIAN, SYS_ROLE_RETAIL],
        component: Loadable({
            loader: () => import('../pages/TaskServiceListPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/task-refund',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_CALL_CENTER, SYS_ROLE_RETAIL],
        component: Loadable({
            loader: () => import('../pages/TaskRefundListPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/task-no-tech',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_CALL_CENTER, SYS_ROLE_HEAD_TECHNICIAN, SYS_ROLE_RETAIL],
        component: Loadable({
            loader: () => import('../pages/TaskNoTechPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/line-group',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_ADMIN],
        component: Loadable({
            loader: () => import('../pages/LineGroupListPage'),
            loading: LoadingComponent,
        }),
    },

    {
        path: '/machine',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_ADMIN, SYS_ROLE_HEAD_TECHNICIAN],
        component: Loadable({
            loader: () => import('../pages/MachineListPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/branch',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_ADMIN],
        component: Loadable({
            loader: () => import('../pages/BranchListPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/zone',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_ADMIN],
        component: Loadable({
            loader: () => import('../pages/ZoneListPage'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/user',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_ADMIN],
        component: Loadable({
            loader: () => import('../pages/UserProfileListPage'),
            loading: LoadingComponent,
        }),
    },

    {
        path: '/job',
        exact: true,
        auth: true,
        roles: [SYS_ROLE_SUPER_ADMIN, SYS_ROLE_HEAD_TECHNICIAN],
        component: Loadable({
            loader: () => import('../pages/JobListPage'),
            loading: LoadingComponent,
        }),
    },
    // {
    //   path: '/',
    //   exact: true,
    //   auth: false,
    //    roles: [SYS_ROLE_SUPER_ADMIN,SYS_ROLE_ADMIN, SYS_ROLE_CALL_CENTER, SYS_ROLE_HEAD_TECHNICIAN,   SYS_ROLE_PLANNER],
    //   component: Loadable({
    //     loader: () => import('../pages/'),
    //     loading: LoadingComponent,
    //   }),
    // },
];

const getRoutes = () => {
    let routes = [...myRoutes];
    if (isLogin()) {
        routes = routes.filter((r) => {
            if (r.roles && r.roles.length > 0 && !isRoutePermission(r.roles)) {
                return false;
            }
            return true;
        });
    }
    routes.push({
        path: '*',
        exact: true,
        auth: false,
        component: Loadable({
            loader: () => import('../pages/NotFoundPage'),
            loading: LoadingComponent,
        }),
    });
    return routes;
};

export default getRoutes;
