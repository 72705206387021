import React from 'react';
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Base from '../components/Layout/Base';
import BasePage from '../components/Layout/BasePage';

import PublicRoutes from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import getRoutes from './routes';
import { isLogin } from 'Utils/authUtils';

export default function Routes() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = ['/', '/login'];

function AppRoutes() {
  let location = useLocation();
  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };
  const animationName = 'rag-fadeIn';
  if (!isLogin() || listofPages.indexOf(location.pathname) > -1) {
    return (
      <BasePage>
        <Switch location={location}>
          {getRoutes().map((route, i) => {
            if (route.auth) {
              return <PrivateRoute key={i} {...route} />;
            }
            return <PublicRoutes key={i} {...route} />;
          })}
        </Switch>
      </BasePage>
    );
  } else {
    return (
      <Base>
        <TransitionGroup>
          <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
            <div>
              <Switch location={location}>
                {getRoutes().map((route, i) => {
                  if (route.auth) {
                    return <PrivateRoute key={i} {...route} />;
                  }
                  return <PublicRoutes key={i} {...route} />;
                })}
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
}
