import React from 'react';

export const DropdownContext = React.createContext({});

export const DropdownProvider = DropdownContext.Provider;

export const DropdownConsumer = DropdownContext.Consumer;

export const BranchContext = React.createContext(null);

export const IssueContext = React.createContext(null);

export const ZoneContext = React.createContext(null);

export const UserContext = React.createContext([]);

export const BankContext = React.createContext([]);

