import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from 'Utils/authUtils';

const PublicRoutes = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => (!isLogin() ? <Component {...props} /> : <Redirect to="/dashboard" />)} />;
};

PublicRoutes.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default PublicRoutes;
