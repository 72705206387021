import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { AvatarImg } from 'components/Common/Display';
import { getCurrentUserProfile } from 'Utils/Common';

class SidebarUserBlock extends Component {
  state = {
    showUserBlock: true,
    user: getCurrentUserProfile(),
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.showUserBlock !== this.props.showUserBlock) {
      this.setState({ showUserBlock: newProps.showUserBlock });
    }
  }

  render() {
    const { user } = this.state;
    return (
      <>
        <Collapse id="user-block" isOpen={this.state.showUserBlock}>
          <div className="bb">
            <div className="item user-block">
              {/* User picture */}
              <div className="user-block-picture">
                <div className="user-block-status">
                  <AvatarImg className="img-thumbnail rounded-circle thumb48" src={`${user ? user.line.pictureUrl : ''}`} />
                  <div className="circle bg-success circle-lg"></div>
                </div>
              </div>
              {/* Name and Job */}
              {user && (
                <div className="user-block-info">
                  {user.line?.displayName} {user.alias ? `(${user.alias})` : ''}
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </>
    );
  }
}

SidebarUserBlock.propTypes = {
  showUserBlock: PropTypes.bool,
};

const mapStateToProps = (state) => ({ showUserBlock: state.settings.showUserBlock });

export default connect(mapStateToProps)(SidebarUserBlock);
