import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="footer-container">
        <div className="d-flex justify-content-between">
          <span>&copy; {year}</span>
          <span>{process.env.REACT_APP_VERSION}</span>
        </div>
      </footer>
    );
  }
}

export default Footer;
