import React from 'react';
import Avatar from 'react-avatar';
import DisplayUserRole from './DisplayUserRole';
export { DisplayUserRole };

export const AvatarImg = ({ src, name, className, id, alt }) => {
  return (
    <>
      {src ? (
        <img id={id} className={className} src={src} alt={alt} />
      ) : (
        <Avatar id={id} className={className} name={`${name}`} />
      )}
    </>
  );
};

AvatarImg.defaultProps = {
  className: 'rounded thumb16',
  id: '',
  alt: '',
};
