import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { debugContextDevtool } from 'react-context-devtool';

import configureStore from './store/store';
const store = configureStore();

const isDev = process.env.NODE_ENV === 'development';

const container = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  container,
);

// Attach root container
debugContextDevtool(container, { debugContext: isDev });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
