import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Base = (props) => {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <section className="section-container">{props.children}</section>
      <Footer />
    </div>
  );
};

Base.propTypes = {
  isLogin: PropTypes.bool,
};

const mapStateToProps = (state) => ({ isLogin: state.settings.isLogin });

export default connect(mapStateToProps)(Base);

// export default Base;
