import { storageKey } from '../config/constants';

import jwt from 'jwt-decode';

export const getCurrentUserProfile = (xToken) => {
  try {
    xToken = xToken || getToken();
    if (!xToken) return null;
    return JSON.parse(JSON.stringify(jwt(xToken)));
  } catch (er) {
    return null;
  }
};

export const getToken = () => {
  const token = localStorage.getItem(storageKey);
  return token ? token : null;
};

export const removeSession = () => {
  localStorage.removeItem(storageKey);
  sessionStorage.removeItem(storageKey);
};

export const setTokenSession = (token) => {
  localStorage.setItem(storageKey, token);
};
