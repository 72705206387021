import React from 'react';
import { Badge } from 'reactstrap';

const DisplayUserRole = (props) => {
  if (!props.roles) return <></>;
  return props.roles.map((item, key) => (
    <Badge key={'usr-role-' + key} color="info" className="mr-1">
      <span className="text-uppercase">{item.label}</span>
    </Badge>
  ));
};

DisplayUserRole.defaultProps = {
  roles: [],
};

export default DisplayUserRole;
