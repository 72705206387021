import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
// App Routes
import Routes from './Routes';

/**
 * Vendor dependencies
 */
import './Vendor';

/**
 * Application Styles
 */
import './styles/bootstrap.scss';
import './styles/app.scss';

import { lookupDropdown } from 'apis';
import { DropdownProvider } from 'context/LookUpContext';
import { AuthContext } from 'context/AuthContext';
import { setTokenSession } from 'Utils/Common';
import { logoutAndRedirect } from 'Utils/authUtils';
import { getCurrentUserProfile } from 'Utils/Common';
import { getToken } from 'Utils/Common';
import { isLogin } from 'Utils/authUtils';
import Swal from 'sweetalert2';

let logoutTimer;

const App = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const [dd, setDd] = useState({});
  useEffect(() => {
    if (token)
      lookupDropdown().then((result) => {
        const { code, data } = result;
        if (code === 0) setDd(data);
      });
  }, [token]);

  const login = useCallback((_token) => {
    const profile = getCurrentUserProfile(_token);
    if (profile && profile.exp) {
      setToken(_token);
      setTokenExpirationDate(profile.exp);
      setTokenSession(_token);
    }
  }, []);

  const logout = useCallback(() => {
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'Session Timeout',
      allowOutsideClick: () => false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      customClass: {
        container: 'my-swal2',
      },
    }).then(() => {
      setToken(null);
      logoutAndRedirect();
    });
  }, []);

  useEffect(() => {
    if (isLogin()) {
      login(getToken());
    }
  }, [login]);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const dtTokenExpiration = new Date(tokenExpirationDate * 1000);
      const remainingTime = dtTokenExpiration.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        remainingTime: moment.unix(tokenExpirationDate).from(),
        login: login,
        logout: logout,
      }}
    >
      <DropdownProvider value={dd}>
        <Routes />
      </DropdownProvider>
    </AuthContext.Provider>
  );
};

export default App;
